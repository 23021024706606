import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
        <footer>
            © 2024 Важливий лише кінцевий результат
        </footer>
    )
  }
}

export default Footer
