import React, { Component } from 'react'
import Helmet from 'react-helmet';

class Error extends Component {
    render() {
        return (
            <div className='error'>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <h1>Помилка 404. Сторінка не знайдена</h1>
                <img src="/404.jpg" alt="404, сторінку і зображення не знайдено"></img>
                <a href="/">
                    <h3>На головну</h3>
                </a>
            </div>
        )
    }
}

export default Error
