import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaPhone } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import React from 'react';
import { useState } from 'react';

const Contacts = () => {
    const [validated, setValidated] = useState(false);
    //Вставляем на страницу как есть. Google со своими ключами, платными аккаунтами, тоннами кода для простой вставки карты и прочими методами вызова электронного дявола ИДЁТ НАХУЙ! В жопу все эти @react-google-maps/api', @vis.gl/react-google-maps и прочее конченное дерьмо!
    const code = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2545.224189994067!2d30.441894276552077!3d50.362373971575536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4c84e026909e5%3A0x3a3d58bf2608051e!2z0IbQvdGB0YLQuNGC0YPRgiDQutGW0LHQtdGA0L3QtdGC0LjQutC4INGW0LzQtdC90ZYg0JIu0Jwu0JPQu9GD0YjQutC-0LLQsCDQndCQ0J0g0KPQutGA0LDRl9C90Lg!5e0!3m2!1suk!2sua!4v1706796158367!5m2!1suk!2sua" style="min-height: 300px; border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

    function handleSubmit(e) {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        }
        else 
        {
            //Собрать нормальный FormData для PHPMailer ибо полученный из e.currentTarget name_input и прочее, сука, почему-то не содержит.
            let formData = new FormData();
            formData.append("name_input", e.currentTarget.name_input.value);
            formData.append("email_input", e.currentTarget.email_input.value);
            formData.append("subject_input", e.currentTarget.subject_input.value);
            formData.append("message_input", e.currentTarget.message_input.value);

            fetch("../mailer/send_mail.php", {
                method: "POST",
                body: formData,
            }).then(response => {
                if (response.ok) {
                    //Через e.currentTarget не обнуляется, потому обнуляем так
                    document.getElementById('name_input').value = "";
                    document.getElementById('email_input').value = "";
                    document.getElementById('subject_input').value = "";
                    document.getElementById('message_input').value = "";
                    alert("Лист надіслано. Дякуємо за звернення");
                } else {
                    console.log("Помилки: ", response.status, response);
                    alert("Не вдалося надіслати лист. Присмо вибачення");
                }
            });

            setValidated(false);
        }
    }

    return (
        <div className="contacts">
            <h1><b>Контакти:</b></h1> 
            <Container>
                <Row>
                    <Col sm={12} md={6} style={{ borderRight: '1px solid #e4e7ea' }}>
                        <div className="contact">
                            <p><FaPhone className="my-icon" /><b>&nbsp;&nbsp;Телефон:</b></p>
                            <p>+38-067-596-08-57</p>
                        </div>
                        <div className="contact">
                            <p><FaMapMarkerAlt className="my-icon" /><b>&nbsp;&nbsp;E-mail:</b></p>
                            <p>newjulia1979@gmail.com</p>
                        </div>
                        <div className="contact">
                            <p><FaEnvelope className="my-icon" /><b>&nbsp;&nbsp;Адреса:</b></p>
                            <p>Київ, 03187, Україна,<br />Проспект Академіка Глушкова, 40</p>
                        </div>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: code }} />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <p className="p_form"><b>Форма зворотного зв'язку</b></p>
                        <Form id="formContacts" noValidate validated={validated} onSubmit={handleSubmit} method="POST">
                            <Form.Group className="mb-3">
                                <Form.Label>Ім'я:</Form.Label>
                                <Form.Control type="text" placeholder="Введіть ім'я" id="name_input" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>E-mail:</Form.Label>
                                <Form.Control required type="email" placeholder="Введіть e-mail" id="email_input" />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    Будь ласка введіть e-mail правильно.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Тема:</Form.Label>
                                <Form.Control type="text" placeholder="Введіть тему листа" id="subject_input" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Повідомлення:</Form.Label>
                                <Form.Control required type="text" as="textarea" style={{ height: '352px' }} id="message_input" />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    Будь ласка введіть текст повідомлення.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" variant="primary">Надіслати</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Contacts
