import React from "react";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Header from "./components/Header";
import Documents from "./components/Documents";
import Contacts from "./components/Contacts";
import Home from "./components/Home";
import Error from "./components/Error";
import Footer from "./components/Footer";
import ButtonUp from "./components/ButtonUp";
import { BrowserRouter as Router, Routes, Route, Link  } from 'react-router-dom';

class App extends React.Component {
  render() {
    return (
        <Container> 
            <Stack gap={3}>
              <div className="wrapper">
                
              <Header />

              <Router>
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route path="/document" element={<Documents />} />
                  <Route exact path="/contact" element={<Contacts />} />
                  <Route path="*" element={<Error />} />
                </Routes>
              </Router>

              <ButtonUp />
              <Footer />

              </div>
            </Stack>
        </Container>
    );
  }
}

export default App;
