import React from 'react'

const InsertDocument = (props) => {
  const [html, setHtml] = React.useState(""); 

  React.useEffect(() => {
    if (props.document) { 
      fetch(props.document.path)
        .then((response) => response.text())
        .then((text) => setHtml(text))
        .catch((error) => {
          setHtml("Ошибка загрузки документа");
          console.error("Error fetching content:", error);
        });
    }
  }, [props]);

  return (
    <div>
      {props.document.id <= 1 && <p className="insertDocumentLink" ></p>}
      {props.document.id > 1 && <p className="insertDocumentLink" >Посилання на цей закон на на офіційному сайті - <a href={props.document.link}>link</a></p>}
      <div className="insertDocument" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default InsertDocument
