import React from "react";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DocumentsList from "./DocumentsList";
import InsertDocument from "./InsertDocument";

class Documents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      documents: [
        {
          id: 0,
          title: "Статут громадської спілки: Спортивної федерація безпілотної авіації «Аеро – Комбат. Простір»",
          type: "~Статути",
          path: "./documents/Статут громадської спілки Спортивної федерація безпілотної авіації «Аеро – Комбат. Простір».html",
          link: ""
        },
        {
          id: 1,
          title: "Статут товариства з обмеженою відповідальністю «Виробниче-експериментальний консорціум Технопарк Меридіан»",
          type: "~Статути",
          path: "./documents/Статут товариства з обмеженою відповідальністю «Виробниче-експериментальний консорціум Технопарк Меридіан».html",
          link: ""
        },
        {
          id: 2,
          title: "Про інноваційну діяльність",
          type: "Кластер",
          path: "./documents/Про інноваційну діяльність.html",
          link: "https://zakon.rada.gov.ua/laws/show/40-15#Text"
        },
        {
          id: 3,
          title: "Про індустріальні парки",
          type: "Кластер",
          path: "./documents/Про індустріальні парки.html",
          link: "https://zakon.rada.gov.ua/laws/show/5018-17#Text"
        },
        {
          id: 4,
          title: "Про наукову і науково-технічну діяльність",
          type: "Кластер",
          path: "./documents/Про наукову і науково-технічну діяльність.html",
          link: "https://zakon.rada.gov.ua/laws/show/848-19#Text"
        },
        {
          id: 5,
          title: 'Про науковий парк "Київська політехніка"',
          type: "Кластер",
          path: "./documents/Про науковий парк Київська політехніка.html",
          link: "https://zakon.rada.gov.ua/laws/show/523-16#Text"
        },
        {
          id: 6,
          title: "Про внесення змін до деяких законів України щодо активізації діяльності та розвитку наукових парків",
          type: "Кластер",
          path: "./documents/Про внесення змін до деяких законів України щодо активізації діяльності та розвитку наукових парків.html",
          link: "https://zakon.rada.gov.ua/laws/show/1714-20#Text"
        },
        {
          id: 7,
          title: "Про охорону культурної спадщини",
          type: "Культура",
          path: "./documents/Про охорону культурної спадщини.html",
          link: "https://zakon.rada.gov.ua/laws/show/1805-14#Text"
        },
        {
          id: 8,
          title: "Про схвалення Концепції Державної цільової науково-технічної програми розвитку авіаційної промисловості на 2021-2030 роки",
          type: "Авіація",
          path: "./documents/Про схвалення Концепції Державної цільової науково-технічної програми розвитку авіаційної промисловості на 2021-2030 роки.html",
          link: "https://zakon.rada.gov.ua/laws/show/1412-2020-%D1%80#Text"
        },
        {
          id: 9,
          title: "Про затвердження Правил виконання польотів безпілотними авіаційними комплексами державної авіації України",
          type: "Авіація",
          path: "./documents/Про затвердження Правил виконання польотів безпілотними авіаційними комплексами державної авіації України.html",
          link: "https://zakon.rada.gov.ua/laws/show/z0031-17#Text"
        },
        {
          id: 10,
          title: "Щодо розвитку авіабудівної галузі України",
          type: "Авіація",
          path: "./documents/Щодо розвитку авіабудівної галузі України.html",
          link: "https://www.president.gov.ua/documents/2682021-39273"
        },
        {
          id: 11,
          title: "Про розвиток літакобудівної промисловості",
          type: "Авіація",
          path: "./documents/Про розвиток літакобудівної промисловості.html",
          link: "https://zakon.rada.gov.ua/laws/show/2660-14#Text"
        },
        {
          id: 12,
          title: "Про затвердження Положення про Державну авіаційну службу України",
          type: "Авіація",
          path: "./documents/Про затвердження Положення про Державну авіаційну службу України.html",
          link: "https://zakon.rada.gov.ua/laws/show/520-2014-%D0%BF#Text"
        },
        {
          id: 13,
          title: "Про особливості реформування підприємств оборонно-промислового комплексу державної форми власності",
          type: "ЗСУ",
          path: "./documents/Про особливості реформування підприємств оборонно-промислового комплексу державної форми власності.html",
          link: "https://zakon.rada.gov.ua/laws/show/1630-20#Text"
        },
        {
          id: 14,
          title: "Про затвердження Порядку розроблення, освоєння та випуску нових видів продукції оборонного призначення, а також припинення випуску існуючих видів такої продукції",
          type: "ЗСУ",
          path: "./documents/Про затвердження Порядку розроблення, освоєння та випуску нових видів продукції оборонного призначення.html",
          link: "https://zakon.rada.gov.ua/laws/show/234-2021-%D0%BF#Text"
        },
        {
          id: 15,
          title: 'Про рішення Ради національної безпеки і оборони України від 18 червня 2021 року "Про Стратегію розвитку оборонно-промислового комплексу України"',
          type: "ЗСУ",
          path: "./documents/Про рішення Ради національної безпеки і оборони України від 18 червня 2021 року Про Стратегію розвитку оборонно-промислового комплексу.html",
          link: "https://zakon.rada.gov.ua/laws/show/372/2021#Text"
        },
        {
          id: 16,
          title: "Про затвердження Інструкції про організацію військової підготовки громадян України за програмою підготовки офіцерів запасу",
          type: "ЗСУ",
          path: "./documents/Про затвердження Інструкції про організацію військової підготовки громадян України за програмою підготовки офіцерів запасу.html",
          link: "https://zakon.rada.gov.ua/laws/show/z1678-15#Text"
        },
        {
          id: 17,
          title: "Про схвалення Концепції Державної цільової соціальної програми розвитку професійної (професійно-технічної) освіти на 2022-2027 роки",
          type: "МОН",
          path: "./documents/Про схвалення Концепції Державної цільової соціальної програми розвитку професійної (професійно-технічної) освіти на 2022-2027 роки.html",
          link: "https://zakon.rada.gov.ua/laws/show/1619-2021-%D1%80#Text"
        },
        {
          id: 18,
          title: "Про затвердження Порядку реалізації права на академічну мобільність",
          type: "МОН",
          path: "./documents/Про затвердження Порядку реалізації права на академічну мобільність.html",
          link: "https://zakon.rada.gov.ua/laws/show/579-2015-%D0%BF#Text"
        },
        {
          id: 19,
          title: "Закон України Про освіту",
          type: "МОН",
          path: "./documents/Закон України Про освіту.html",
          link: "https://zakon.rada.gov.ua/laws/show/2145-19#Text"
        },
        {
          id: 20,
          title: "Про позашкільну освіту",
          type: "МОН",
          path: "./documents/Про позашкільну освіту.html",
          link: "https://zakon.rada.gov.ua/laws/show/1841-14#Text"
        },
        {
          id: 21,
          title: "Про затвердження Положення про міжшкільний навчально-виробничий комбінат",
          type: "МОН",
          path: "./documents/Про затвердження Положення про міжшкільний навчально-виробничий комбінат.html",
          link: "https://zakon.rada.gov.ua/laws/show/z0177-93#Text"
        },
        {
          id: 22,
          title: "Про затвердження Положення про міжшкільний ресурсний центр",
          type: "МОН",
          path: "./documents/Про затвердження Положення про міжшкільний ресурсний центр.html",
          link: "https://zakon.rada.gov.ua/laws/show/z0063-19#Text"
        },
        {
          id: 23,
          title: "Про затвердження переліку типів позашкільних навчальних закладів і Положення про позашкільний навчальний заклад",
          type: "Спорт",
          path: "./documents/Про затвердження переліку типів позашкільних навчальних закладів і Положення про позашкільний навчальний заклад.html",
          link: "https://zakon.rada.gov.ua/laws/show/433-2001-%D0%BF#Text"
        },
        {
          id: 24,
          title: "Про затвердження Типового статуту об’єднання єврорегіонального співробітництва",
          type: "Різне",
          path: "./documents/Про затвердження Типового статуту об’єднання єврорегіонального співробітництва.html",
          link: "https://zakon.rada.gov.ua/laws/show/1127-2019-%D0%BF#Text"
        },
        {
          id: 25,
          title: "Про затвердження Типової угоди про утворення об’єднання єврорегіонального співробітництва",
          type: "Різне",
          path: "./documents/Про затвердження Типової угоди про утворення об’єднання єврорегіонального співробітництва.html",
          link: "https://zakon.rada.gov.ua/laws/show/1126-2019-%D0%BF#Text"
        }
        //2 кривых документа по самой структуре текст (полосы прокрутки и т. п., если надо, нужно в них править сам текст):
        //Про затвердження переліку типів позашкільних навчальних закладів і Положення про позашкільний навчальний заклад
        //Про затвердження Положення про міжшкільний навчально-виробничий комбінат
      ],
      showNumberDocument: 2
    }
    this.onShowDocument = this.onShowDocument.bind(this)
  }

  render() {
    return (
      <div className="documents">
        <Container>
          <Row>
            <Col sm={12} md={4} className="panel-left">
                <DocumentsList onShowDocument={this.onShowDocument} documents={this.state.documents} showNumberDocument={this.state.showNumberDocument} />
            </Col>
            <Col sm={12} md={8}>
                <InsertDocument document={this.state.documents.find(document => document.id === this.state.showNumberDocument)} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  //Прочитать из localStorage значение showNumberDocument при обновлении страницы
  componentDidMount() {
    const storedShowNumberDocument = localStorage.getItem('showNumberDocument');
    if (storedShowNumberDocument) {
      try {
        this.setState({ showNumberDocument: JSON.parse(storedShowNumberDocument) });
      } catch (error) {
        console.error('Error parsing stored showNumberDocument:', error);
      }
    }
  }

  //Записать в localStorage значение showNumberDocument при обновлении страницы
  componentDidUpdate(prevProps, prevState) {
    if (prevState.showNumberDocument !== this.state.showNumberDocument) {
      localStorage.setItem('showNumberDocument', JSON.stringify(this.state.showNumberDocument));
    }
  }

  //Выбрать документ для показа на странице
  onShowDocument(id) {
    this.setState({showNumberDocument: id});
  }
}

export default Documents;