import React, { Component } from 'react'

class Home extends Component {
  render() {
    return (
        <div className="home">
            <img src="../mria.png" width="100%"></img>
            <div>
                <h2>Комплексна, національна ініціатива кадрової, навчальної, фахової підготовки</h2> 
                <p>Цільова вікова фокус група 12 - 30 років.</p>
                <p>Створення національного, індустріального, виробниче-експериментального Кластеру Безпілотної авіації.</p>
            </div>
        </div>
    )
  }
}

export default Home
