import React, { useState, useEffect, memo } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { VscTriangleDown } from "react-icons/vsc";
import { VscTriangleRight } from "react-icons/vsc";
import { Container } from 'react-bootstrap';

const DocumentsList = memo((props) => {
  const [types, setTypes] = useState([]);
  const [openIndexes, setOpenIndexes] = useState([]);

  //Чтение состояния openIndexes из localStorage перед загрузкой страницы
  useEffect(() => {
    const storedOpenIndexes = localStorage.getItem('openIndexes');
    if (storedOpenIndexes) {
      setOpenIndexes(JSON.parse(storedOpenIndexes));
    }
    const uniqueTypes = [...new Set(props.documents.map((document) => document.type))];
    setTypes(uniqueTypes.sort());
  }, [props.documents]);

  //Перед перезагрузкой страницы записать в localStorage openIndexes
  window.addEventListener('beforeunload', (event) => {
    //localStorage.setItem('openIndexes', JSON.stringify(openIndexes)); //записать все индексы
    let ii = 0;
    openIndexes.forEach(ind => {
      if (props.documents[props.showNumberDocument].type === types[ind]) ii = ind;
    });
    localStorage.setItem('openIndexes', JSON.stringify([ii]));
  });

  return (
    <div>
      {types.map((type, i) => {
        const documents = props.documents.filter((document) => document.type === type).sort((a, b) => a.title.localeCompare(b.title));
        return (
          <div className={props.documents[props.showNumberDocument].type === type ? "documents-links active" : "documents-links"} key={type}>
            <a onClick={() => {
              setOpenIndexes((prevOpenIndexes) => {
                const index = prevOpenIndexes.indexOf(i);
                if (index !== -1) {
                  return prevOpenIndexes.filter((el) => el !== i);
                } else {
                  return [...prevOpenIndexes, i];
                }
              });
            }}
              aria-controls={`documents${i}`}
              aria-expanded={openIndexes.includes(i)}
            >
              <Stack direction="horizontal" gap={2}>
                {openIndexes.includes(i) && <VscTriangleDown className="my-icon"/>}
                {!openIndexes.includes(i) && <VscTriangleRight className="my-icon"/>}
                {type === "~Статути" && <h4>{type.replace("~", "")}</h4>}
                {type !== "~Статути" && <h4>{type}</h4>}
              </Stack>
            </a>

            <Collapse in={openIndexes.includes(i)} >
              <div id={`documents${i}`}>
                {documents.map((document) => (
                  <div className={props.showNumberDocument === document.id ? "documents-link active" : "documents-link"} onClick={() => {props.onShowDocument(document.id);}} key={document.id}>
                    <Container>
                      <Row>
                        <Col xs={1}></Col>
                        <Col xs={11}>{document.title}</Col>
                      </Row>
                    </Container>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
});

export default DocumentsList;
