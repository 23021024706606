import React, { useState, useEffect } from 'react';

const ButtonUp = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="btn-up" style={{ display: show ? 'block' : 'none' }} onClick={handleScrollTop}>
      <img src="up40x40.png" alt="UpBtn"></img>
    </div>
  );
};

export default ButtonUp;